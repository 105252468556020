import { post } from '@/utils/request';

// 拆改统计-获取拆改统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.split/getStatistical',
    data: data
});

// 拆改统计-编辑拆改统计表
const editStatistical = (data) => post({
    url: '/store/statistical.split/editStatistical',
    data: data
})


// 拆改统计-新增拆改统计表
const addStatistical = (data) => post({
    url: '/store/statistical.split/addStatistical',
    data: data
})

// 拆改统计-获取拆改统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.split/getStatisticalList',
    data: data
})

// 拆改统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.split/totalStatisticalData',
    data: data
})

// 拆改统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.split/totalStatisticalRadar',
    data: data
})

// 拆改统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.split/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.split/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});


// 导出
const downloadList = (data) => post({
    url: '/store/statistical.split/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.split/deleteStatistical',
    data: data
})

// 仓库
const getWarehouseListAll = (data) => post({
    url: '/store/goods.EntryCtl/getWarehouseListAll',
    data: data
})



export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    getAttrValueList,
    downloadList,
    getMaterialListReq,
    deleteStatistical,
    getWarehouseListAll
}